.modalOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.modalOutcomeText {
  font-size: 1rem;
  color: black;
}

/* Media query for devices with a max-width of 600px */
@media (max-width: 600px) {
  .gameOutcomeModal {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    /* Larger font size for readability */
  }
}